import React, { FC, useCallback, useRef } from 'react'
import { PageProps } from 'gatsby'
import { useDispatch, useSelector } from 'react-redux'
import { gsap, Power2 } from 'gsap'

import withMemo from '../../decorators/withMemo'
import { Layout } from '../../components'
import { CaptureScreenTemplate, FinalScreenTemplate, HomepageTemplate } from '../../templates'
import { actions, selectors } from '../../redux'
import { Screen } from '../../types/global'
import { CaptureTemplateProps } from '../../templates/CaptureScreen'
import { FinalScreenTemplateProps } from '../../templates/FinalScreen'
import { HomepageTemplateProps } from '../../templates/Homepage'

import * as SC from './styled'

const RootContainer: FC<PageProps> = () => {
  const dispatch = useDispatch()

  // ref

  const $pageTransition = useRef(null)

  // SELECTORS

  const screen = useSelector(selectors.app.screen)
  const photoMode = useSelector(selectors.app.photoMode)
  const photoThemeIndex = useSelector(selectors.app.photoThemeIndex)
  const shareUrl = useSelector(selectors.app.shareUrl)
  const imageRenditions = useSelector(selectors.app.imageRenditions)

  // ANIMATION

  const animateStartTransition = useCallback(() => {
    const tl = gsap.timeline()

    tl.set($pageTransition.current, {
      transformOrigin: 'right center',
    })
      .to($pageTransition.current, {
        scaleX: 1,
        duration: 0.7,
        ease: Power2.easeInOut,
      })
      .add(() => {
        dispatch(actions.app.setScreen({ screen: Screen.capture }))
      })
      .set($pageTransition.current, {
        transformOrigin: 'left center',
      })
      .to(
        $pageTransition.current,
        {
          scaleX: 0,
          duration: 0.7,
          ease: Power2.easeInOut,
        },
        '+=0.25'
      )
  }, [dispatch])

  const animateRestartTransition = useCallback(() => {
    const tl = gsap.timeline()

    tl.set($pageTransition.current, {
      transformOrigin: 'right center',
    })
      .to($pageTransition.current, {
        scaleX: 1,
        duration: 0.7,
        ease: Power2.easeInOut,
      })
      .add(() => {
        dispatch(actions.app.restart())
      })
      .set($pageTransition.current, {
        transformOrigin: 'left center',
      })
      .to(
        $pageTransition.current,
        {
          scaleX: 0,
          duration: 0.7,
          ease: Power2.easeInOut,
        },
        '+=0.25'
      )
  }, [dispatch])

  // HANDLERS

  const handleOnStartClick: HomepageTemplateProps['onStartClick'] = useCallback(() => {
    animateStartTransition()
  }, [animateStartTransition])

  const handleOnPictureTaken: CaptureTemplateProps['onPictureTaken'] = useCallback(
    (image, theme, mode) => {
      dispatch(actions.app.sendBase64Image({ image, theme, mode }))

      dispatch(actions.app.setScreen({ screen: Screen.final }))
    },
    [dispatch]
  )

  const handleOnThemeIndexChange: CaptureTemplateProps['onThemeIndexChange'] = useCallback(
    (index) => {
      dispatch(actions.app.setPhotoThemeIndex({ index }))
    },
    [dispatch]
  )

  const handleOnModeChange: CaptureTemplateProps['onModeChange'] = useCallback(
    (mode) => {
      dispatch(actions.app.setPhotoMode({ mode }))
    },
    [dispatch]
  )

  const handleOnRestartClick: FinalScreenTemplateProps['onRestartClick'] = useCallback(() => {
    animateRestartTransition()
  }, [animateRestartTransition])

  // RETURN

  return (
    <Layout>
      {screen === Screen.home && <HomepageTemplate onStartClick={handleOnStartClick} />}
      {screen === Screen.capture && (
        <CaptureScreenTemplate
          mode={photoMode}
          themeIndex={photoThemeIndex}
          onPictureTaken={handleOnPictureTaken}
          onThemeIndexChange={handleOnThemeIndexChange}
          onModeChange={handleOnModeChange}
        />
      )}
      {screen === Screen.final && (
        <FinalScreenTemplate
          shareUrl={shareUrl}
          imageRenditions={imageRenditions}
          onRestartClick={handleOnRestartClick}
        />
      )}
      <SC.PageTransition ref={$pageTransition} />
      <SC.Flash className="flash" />
    </Layout>
  )
}

export default withMemo(RootContainer)
