import styled from 'styled-components'

export const Flash = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background-color: white;
  opacity: 0;
  z-index: 200;
  pointer-events: none;
`

export const PageTransition = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background-color: white;
  pointer-events: none;
  z-index: 200;
  transform: scaleX(0);
  transform-origin: right center;
`
